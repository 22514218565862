import aboutMe from '../img/socials/about-me.png';
import angelist from '../img/socials/angelist.svg';
import badoo from '../img/socials/badoo.svg';
import bandcamp from '../img/socials/bandcamp.svg';
import behance from '../img/socials/behance.svg';
import bitbucket from '../img/socials/bitbucket.svg';
import bitly from '../img/socials/bitly.svg';
import campsite from '../img/socials/campsite.png';
import canva from '../img/socials/canva.svg';
import codecademy from '../img/socials/codecademy.svg';
import contactinbio from '../img/socials/contactinbio.png';
import cybrary from '../img/socials/cybrary.png';
import dailymotion from '../img/socials/dailymotion.svg';
import disqus from '../img/socials/disqus.svg';
import dribble from '../img/socials/dribble.svg';
import ebay from '../img/socials/ebay.svg';
import facebook from '../img/socials/facebook.svg';
import fanpop from '../img/socials/fanpop.svg';
import fiverr from '../img/socials/fiverr.svg';
import flickr from '../img/socials/flickr.svg';
import flipboard from '../img/socials/flipboard.svg';
import fotolog from '../img/socials/fotolog.png';
import foursquare from '../img/socials/foursquare.svg';
import gamespot from '../img/socials/gamespot.png';
import github from '../img/socials/github.svg';
import googleplus from '../img/socials/googleplus.svg';
import gravatar from '../img/socials/gravatar.svg';
import houzz from '../img/socials/houzz.svg';
import hubpages from '../img/socials/hubpages.svg';
import imgur from '../img/socials/imgur.svg';
import instagram from '../img/socials/instagram.svg';
import instructables from '../img/socials/instructables.png';
import keybase from '../img/socials/keybase.svg';
import kongregate from '../img/socials/kongregate.png';
import linkedin from '../img/socials/linkedin.svg';
import linktree from '../img/socials/linktree.png';
import medium from '../img/socials/medium.svg';
import mixcloud from '../img/socials/mixcloud.png';
import myspace from '../img/socials/myspace.svg';
import patreon from '../img/socials/patreon.svg';
import paypal from '../img/socials/paypal.svg';
import photobucket from '../img/socials/photobucket.svg';
import pinterest from '../img/socials/pinterest.svg';
import quora from '../img/socials/quora.svg';
import reddit from '../img/socials/reddit.svg';
import roblox from '../img/socials/roblox.svg';
import shorBy from '../img/socials/shor-by.png';
import slideshare from '../img/socials/slideshare.svg';
import snapchat from '../img/socials/snapchat.svg';
import soundcloud from '../img/socials/soundcloud.svg';
import spotify from '../img/socials/spotify.svg';
import steam from '../img/socials/steam.svg';
import swipop from '../img/socials/swipop.png';
import tapBio from '../img/socials/tap-bio.png';
import telegram from '../img/socials/telegram.svg';
import tiktok from '../img/socials/tiktok.svg';
import trello from '../img/socials/trello.svg';
import tripadvisor from '../img/socials/tripadvisor.svg';
import tumblr from '../img/socials/tumblr.svg';
import twitch from '../img/socials/twitch.svg';
import twitter from '../img/socials/twitter.svg';
import vimeo from '../img/socials/vimeo.svg';
import vine from '../img/socials/vine.svg';
import vkontakte from '../img/socials/vkontakte.png';
import wattpad from '../img/socials/wattpad.svg';
import wikipedia from '../img/socials/wikipedia.svg';
import wordpress from '../img/socials/wordpress.svg';
import youtube from '../img/socials/youtube.svg';
import archive from '../img/socials/archive.svg';
import GaG from '../img/socials/9gag.svg';
import Academia from '../img/socials/academia.png';
import appleDiscussions from '../img/socials/apple-discussion.svg';
import askFedora from '../img/socials/askFedora.png';
import askFM from '../img/socials/askfm.svg';
import binarySearch from '../img/socials/binarySearch.png';
import bitcoinforum from '../img/socials/bitcoinforum.png';
import blogger from '../img/socials/blogger.svg';

import bookcrossing from '../img/socials/bookcrossing.png';
import buymeacoffee from '../img/socials/buymeacoffee.png';
import CapFriendly from '../img/socials/CapFriendly.png';
import Chess from '../img/socials/chess.svg';
import CloudflareCommunity from '../img/socials/CloudflareCommunity.png';
import Codecademy from '../img/socials/Codecademy.svg';
import Codepen from '../img/socials/Codepen.svg';
import Codewars from '../img/socials/codewars.svg';
import Contently from '../img/socials/Contently.png';
import Coroflot from '../img/socials/Coroflot.png';
import Countable from '../img/socials/Countable.png';
import Cracked from '../img/socials/Cracked.png';
import Crevado from '../img/socials/Crevado.png';
import dev from '../img/socials/dev.svg';
import Designspiration from '../img/socials/Dspncdn.png';
// import DeviantART from '../img/socials/Deviantart.png';
import Discogs from '../img/socials/Discogs.png';
import DiscussElastic from '../img/socials/DiscussElastic.png';
import DockerHub from '../img/socials/docker.svg';
import Dribbble from '../img/socials/dribbble.svg';
import Duolingo from '../img/socials/Duolingo.png';
import ello from '../img/socials/ello.svg';
import etsy from '../img/socials/etsy.png';
import euw from '../img/socials/euw.png';
import eyeem from '../img/socials/eyeem.png'
import f3 from '../img/socials/f3.png';
import fandom from '../img/socials/fandom.png'
import flightradar24 from '../img/socials/flightradar24.svg';
import quizlet from '../img/socials/quizlet.png';
import producthunt from '../img/socials/producthunt.svg';
import tinder from '../img/socials/tinder.svg';
import wix from '../img/socials/wix.svg';
import uid from '../img/socials/uid.png';
import pr0gramm from '../img/socials/pr0gramm.png';
import pikabu from '../img/socials/pikabu.png';
import opennet from '../img/socials/opennet.png';
import npm from '../img/socials/npm.png';
import livelib from '../img/socials/livelib.png';
import slack from '../img/socials/slack.svg';


import akniga from '../img/socials/akniga.png';
import Blogger from '../img/socials/Blogger.png';
import CNET from '../img/socials/CNET.png';
import couchsurfing from '../img/socials/couchsurfing.png';
import datingRU from '../img/socials/datingRU.png';
import download from '../img/socials/download.png';
import Etsy from '../img/socials/Etsy.png';
import Facenama from '../img/socials/Facenama.png';
import geocaching from '../img/socials/geocaching.png';
import GetMyUni from '../img/socials/GetMyUni.png';
import gfycat from '../img/socials/gfycat.png';
import Gitee from '../img/socials/Gitee.png';
import GitHub from '../img/socials/GitHub.png';
import goodreads from '../img/socials/goodreads.png';
import Gumroad from '../img/socials/Gumroad.png';
import HackerNews from '../img/socials/HackerNews.png';
import HackerOne from '../img/socials/HackerOne.png';
import HackerRank from '../img/socials/HackerRank.png';
import hackster from '../img/socials/hackster.png';
import HackTheBox from '../img/socials/HackTheBox.png';
import Itch from '../img/socials/Itch.png';
import Jimdo from '../img/socials/Jimdo.png';
import Kaggle from '../img/socials/Kaggle.png';
import Kik from '../img/socials/Kik.png';
import kwork from '../img/socials/kwork.png';
import labpentestit from '../img/socials/labpentestit.png';
import lastfm from '../img/socials/lastfm.png';
import leasehackr from '../img/socials/leasehackr.png';
import LeetCode from '../img/socials/LeetCode.png';
import LiveJournal from '../img/socials/LiveJournal.png';
import Lolchess from '../img/socials/Lolchess.png';
import Memrise from '../img/socials/Memrise.png';
import MyAnimeList from '../img/socials/MyAnimeList.png';
import Naver from '../img/socials/Naver.png';
import NICommunityForum from '../img/socials/NICommunityForum.png';
import OK from '../img/socials/OK.png';
import Otzovik from '../img/socials/Otzovik.png';
import Packagist from '../img/socials/Packagist.png';
import Pastebin from '../img/socials/Pastebin.png';
import PCGamer from '../img/socials/PCGamer.png';
import Periscope from '../img/socials/Periscope.png';
import Pinkbike from '../img/socials/Pinkbike.png';
import Polarsteps from '../img/socials/Polarsteps.png';
import Polygon from '../img/socials/Polygon.png';
import PromoDJ from '../img/socials/PromoDJ.png';
import pypi from '../img/socials/pypi.png';
import Rajcenet from '../img/socials/Rajcenet.png';
import RateYourMusic from '../img/socials/RateYourMusic.png';
import Redbubble from '../img/socials/Redbubble.png';
import Repl from '../img/socials/Repl.png';
import Scratch from '../img/socials/Scratch.png';
import Scribd from '../img/socials/Scribd.png';
import Slashdot from '../img/socials/Slashdot.png';
import SourceForge from '../img/socials/SourceForge.png';
import Splits from '../img/socials/Splits.png';
import Sporcle from '../img/socials/Sporcle.png';
import SublimeForum from '../img/socials/SublimeForum.png';

import Tellonym from '../img/socials/Tellonym.png';
import TradingView from '../img/socials/TradingView.png';
import TrashboxRU from '../img/socials/TrashboxRU.png';
import TryHackMe from '../img/socials/TryHackMe.png';
import Typeracer from '../img/socials/Typeracer.png';
import UltimateGuitar from '../img/socials/UltimateGuitar.png';
import Venmo from '../img/socials/Venmo.png';
import VirusTotal from '../img/socials/VirusTotal.png';
import vk from '../img/socials/vk.png';
import VSCO from '../img/socials/VSCO.png';
import WeHeartIt from '../img/socials/WeHeartIt.png';
import WhonixForum from '../img/socials/WhonixForum.png';
import WordPressOrg from '../img/socials/WordPressOrg.png';
import XboxGamertag from '../img/socials/XboxGamertag.png';
import Zhihu from '../img/socials/Zhihu.png';

import Archiveorg from '../img/socials/Archiveorg.jpg';
import authorSTREAM from '../img/socials/authorSTREAM.jpg';
import Carbonmade from '../img/socials/Carbonmade.jpeg';
import DeviantART from '../img/socials/DeviantART.jpg';
import Freelancer from '../img/socials/Freelancer.jpeg';
import Freesound from '../img/socials/Freesound.jpg';
import Giphy from '../img/socials/Giphy.svg';
import GitHubSupportCommunity from '../img/socials/GitHubSupportCommunity.png';
import GitLab from '../img/socials/GitLab.svg';
import GuruShots from '../img/socials/GuruShots.jpeg';
import irecommend from '../img/socials/irecommend.jpg';
import Itemfix from '../img/socials/Itemfix.jpg';
import Letterboxd from '../img/socials/Letterboxd.jpeg';
import Lichess from '../img/socials/Lichess.jpeg';
import Nightbot from '../img/socials/Nightbot.jpg';
import OracleCommunity from '../img/socials/OracleCommunity.jpg';
import PlayStore from '../img/socials/PlayStore.jpg';
import PokemonShowdown from '../img/socials/PokemonShowdown.jpg';
import PSNProfiles from '../img/socials/PSNProfiles.png';
import ReverbNation from '../img/socials/ReverbNation.png';
import signal from '../img/socials/signal.jpg';
import Smule from '../img/socials/Smule.jpg';
import Speedrun from '../img/socials/Speedrun.jpeg';
import SportsRU from '../img/socials/SportsRU.jpeg';
import tetr from '../img/socials/tetr.svg';
import unsplash from '../img/socials/unsplash.png';


export const socialChannels = {
  '9gag': GaG,
  contactinbio: contactinbio,
  'about.me': aboutMe,
  'academia.edu': Academia,
  angellist: angelist,
  'apple discussions': appleDiscussions,
  // "archive.org": archive,
  'ask fedora': askFedora,
  askfm: askFM,
  badoo: badoo,
  bandcamp: bandcamp,
  behance: behance,
  binarysearch: binarySearch,
  bitbucket: bitbucket,
  bitcoinforum: bitcoinforum,
  bitly: bitly,
  // blogger: blogger,
  bookcrossing: bookcrossing,
  buymeacoffee: buymeacoffee,
  capfriendly: CapFriendly,
  Capfriendly: CapFriendly,
  chess: Chess,
  cloudflarecommunity: CloudflareCommunity,
  codecademy: Codecademy,
  codepen: Codepen,
  codewars: Codewars,
  contently: Contently,
  coroflot: Coroflot,
  countable: Countable,
  cracked: Cracked,
  crevado: Crevado,
  campsite: campsite,
  canva: canva,
  codeacademy: codecademy,
  cybrary: cybrary,
  dailymotion: dailymotion,
  'dev community': dev,
  designspiration: Designspiration,
  discogs: Discogs,
  'discuss.elastic.co': DiscussElastic,
  'docker hub': DockerHub,
  dribbble: Dribbble,
  duolingo: Duolingo,
  disqus: disqus,
  dribble: dribble,
  ebay: ebay,
  ello: ello,
  etsy: etsy,
  euw: euw,
  eyeem: eyeem,
  'f3.cool': f3,
  fandom: fandom,
  flightradar24: flightradar24,
  facebook: facebook,
  fanpop: fanpop,
  fiverr: fiverr,
  flickr: flickr,
  flipboard: flipboard,
  fotolog: fotolog,
  foursquare: foursquare,
  gamespot: gamespot,
  'github support community': github,
  googleplus: googleplus,
  gravatar: gravatar,
  houzz: houzz,
  hubpages: hubpages,
  imgur: imgur,
  instagram: instagram,
  instructables: instructables,
  keybase: keybase,
  kongregate: kongregate,
  linkedin: linkedin,
  linktree: linktree,
  medium: medium,
  mixcloud: mixcloud,
  myspace: myspace,
  patreon: patreon,
  paypal: paypal,
  photobucket: photobucket,
  pinterest: pinterest,
  quora: quora,
  reddit: reddit,
  roblox: roblox,
  'shor.by': shorBy,
  slideshare: slideshare,
  snapchat: snapchat,
  soundcloud: soundcloud,
  spotify: spotify,
  steam: steam,
  swipop: swipop,
  'tap.bio': tapBio,
  telegram: telegram,
  tiktok: tiktok,
  trello: trello,
  tripadvisor: tripadvisor,
  tumblr: tumblr,
  twitch: twitch,
  twitter: twitter,
  vimeo: vimeo,
  vine: vine,
  vkontakte: vkontakte,
  wattpad: wattpad,
  wikipedia: wikipedia,
  wordpress: wordpress,
  youtube: youtube,
  quizlet: quizlet,
  producthunt: producthunt,
  tinder: tinder,
  wix: wix,
  uid: uid,
  pr0gramm: pr0gramm,
  pikabu: pikabu,
  opennet: opennet,
  livelib: livelib,
  slack: slack,
  npm: npm,
  akniga: akniga,
  blogger: Blogger,
  capfriendly: CapFriendly,
  cnet: CNET,
  couchsurfing: couchsurfing,
  datingru: datingRU,
  download: download,
  etsy: Etsy,
  facenama: Facenama,
  geocaching: geocaching,
  getmyuni: GetMyUni,
  gfycat: gfycat,
  gitee: Gitee,
  github: GitHub,
  goodreads: goodreads,
  gumroad: Gumroad,
  hackernews: HackerNews,
  hackerone: HackerOne,
  hackerrank: HackerRank,
  hackster: hackster,
  hackthebox: HackTheBox,
  'itch.io': Itch,
  jimdo: Jimdo,
  kaggle: Kaggle,
  kik: Kik,
  kwork: kwork,
  labpentestit: labpentestit,
  'last.fm': lastfm,
  leasehackr: leasehackr,
  leetcode: LeetCode,
  livejournal: LiveJournal,
  lolchess: Lolchess,
  memrise: Memrise,
  myanimelist: MyAnimeList,
  naver: Naver,
  nicommunityforum: NICommunityForum,
  ok: OK,
  otzovik: Otzovik,
  packagist: Packagist,
  pastebin: Pastebin,
  pcgamer: PCGamer,
  periscope: Periscope,
  pinkbike: Pinkbike,
  polarsteps: Polarsteps,
  polygon: Polygon,
  promodj: PromoDJ,
  'psnprofiles.com': PSNProfiles,
  pypi: pypi,
  'rajce.net': Rajcenet,
  'rate your music': RateYourMusic,
  redbubble: Redbubble,
  repl: Repl,
  scratch: Scratch,
  scribd: Scribd,
  slashdot: Slashdot,
  'splits.io': Splits,
  sporcle: Sporcle,
  sublimeforum: SublimeForum,
  'tellonym.me': Tellonym,
  tradingview: TradingView,
  trashboxru: TrashboxRU,
  tryhackme: TryHackMe,
  typeracer: Typeracer,
  'ultimate-guitar': UltimateGuitar,
  venmo: Venmo,
  virustotal: VirusTotal,
  vk: vk,
  vsco: VSCO,
  'we heart it': WeHeartIt,
  'whonix forum': WhonixForum,
  wordpressorg: WordPressOrg,
  'xbox gamertag': XboxGamertag,
  zhihu: Zhihu,
  'archive.org': Archiveorg,
  authorstream: authorSTREAM,
  carbonmade: Carbonmade,
  deviantart: DeviantART,
  freelancer: Freelancer,
  freesound: Freesound,
  giphy: Giphy,
  // 'gitHub support community': GitHubSupportCommunity,
  gitlab: GitLab,
  gurushots: GuruShots,
  irecommend: irecommend,
  itemfix: Itemfix,
  letterboxd: Letterboxd,
  lichess: Lichess,
  nightbot: Nightbot,
  'oracle community': OracleCommunity,
  playstore: PlayStore,
  'pokemon showdown': PokemonShowdown,
  reverbnation: ReverbNation,
  signal: signal,
  smule: Smule,
  sourceforge: SourceForge,
  'speedrun.com': Speedrun,
  sportsru: SportsRU,
  'tetr.io': tetr,
  unsplash: unsplash
};