import React, {useEffect, useState, useRef, useContext, useCallback, useReducer} from 'react';
import {CSVLink} from 'react-csv';
import Upgrade from '../../components/upgrade';
import {copyIcon, downloadIcon} from '../../img/svg-icons';
import noIcon from '../../img/socials/no-image.svg';
import {UserContext} from '../../context/user-context';
import {login} from '../../requests';
import {socialChannels} from '../../constants';
import googleIcon from '../../img/google-icon.svg';
import './styles.scss';
import {useTranslation} from 'gatsby-plugin-react-i18next';

export default ({result, headerCenter, name}) => {
  const [copied, setCopied] = useState('');
  const {user} = useContext(UserContext);
  const inputElement = useRef(null);
  const [socials, setSocials] = useState(null);
  const [showNotFounds, setShowNotFounds] = useState(false);
  const [socialsFullList, setSocialsFullList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categoriesLables, setCategoriesLables] = useState(['All']);
  const [openModal, setOpenModal] = useReducer(val => !val, false);
  const {t, i18n} = useTranslation();
  
  useEffect(() => {
    const data = [];
    const dataFull = [];
    const categoryList = [];
    if (result) {
      result.map(item => {
        +item.status === 200 &&
          item.category &&
          !categoryList.includes(item.category) &&
          categoryList.push(item.category);
        +item.status === 200 &&
          item.categoryList &&
          !categoriesLables.includes(item.categoryList) &&
          categoryList.push(item.categoryList);
        +item.status === 200 &&
          data.push({
            icon: socialChannels[item.name.toLowerCase()] || noIcon,
            value: item.url,
            name: item.name,
            status: item.status,
            category: item.category,
          });
        dataFull.push({
          icon: socialChannels[item.name.toLowerCase()] || noIcon,
          value: item.url,
          name: item.name,
          status: item.status,
          category: item.category,
        });
      });
    }
    setCategoriesLables(['All', ...categoryList]);
    setSocials(data);
    setSocialsFullList(dataFull);
  }, [result]);

  const handleDownloadCSV = () => {
    const csv = [['name', 'url', 'status', 'category']];
    const res =
      selectedCategory === 'All'
        ? showNotFounds
          ? socialsFullList
          : socials
        : (showNotFounds ? socialsFullList : socials).filter(sub => sub.category === selectedCategory);
    if (res) {
      res.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.name) : (csv[index + 1] = [item.name]);
      });
      res.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.value) : (csv[index + 1] = [item.value]);
      });
      res.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.status) : (csv[index + 1] = [item.status]);
      });
      res.map((item, index) => {
        csv[index + 1] ? csv[index + 1].push(item.category) : (csv[index + 1] = [item.category]);
      });
    }
    return csv;
  };

  useEffect(() => {
    copied && setTimeout(() => setCopied(''), 5000);
  }, [copied]);

  const handleInputClick = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const inputDom = inputElement.current;
    if (!inputDom) {
      return;
    }
    navigator.clipboard.writeText(value || '');
    inputDom.focus();
    inputDom.setSelectionRange(0, value.length);
    document.execCommand('copy');
    setCopied(value);
  };

  const handleLoginOrLogout = useCallback(async () => {
    const url = await login(i18n.language === 'ar' ? 'ar' : 'en');
    if (url) {
      window.location.href.replace(window.location.origin, '') &&
        localStorage.setItem('redirect_url', window.location.href.replace(window.location.origin, ''));
      window.location.href = url;
      // window.location.replace(url);
    }
  }, [user]);

  return (
    <>
      <div className="results-container">
        <div className="socials-contaner">
          {categoriesLables && categoriesLables.length > 0 && (
            <div className="categories-values my-4">
              {categoriesLables.map(item => (
                <div
                  className={`category-item ${selectedCategory === item ? 'active' : ''}`}
                  onClick={() => {
                    if (!(user && user.id) && socials && socials.length > 9) {
                      setOpenModal(true);
                    } else {
                      setSelectedCategory(item);
                    }
                  }}
                >
                  {item} (
                  {item === 'All'
                    ? (socialsFullList || socialsFullList) && (showNotFounds ? socialsFullList : socials).length
                    : socials.filter(sub => sub.category === item).length}
                  )
                </div>
              ))}
            </div>
          )}
          {/* <h5 className="px-4">
          <div className="py-1" style={{fontSize: '14px'}}>
            Total number of results:{' '}
            <strong>
              {(socialsFullList || socialsFullList) && (showNotFounds ? socialsFullList : socials).length}
            </strong>
          </div>
          <label className="checkbox my-auto">
            <input type="checkbox" checked={showNotFounds} onChange={() => setShowNotFounds(!showNotFounds)} />
            Show all links
          </label>
        </h5> */}
          <div className="values-container">
            <h5 className="px-4">
              <label className="checkbox mx-0 my-auto">
                <input type="checkbox" checked={showNotFounds} onChange={() => setShowNotFounds(!showNotFounds)}  className={i18n.language === 'ar' ? "typing-rl" : ''}/>
                {t('show-all-links')}
              </label>
              {headerCenter}
              {!(user && user.id) ? (
                <div className="download-btn my-auto" onClick={() => setOpenModal(true)}>
                  {downloadIcon}
                </div>
              ) : (
                <CSVLink
                  title="Download CSV"
                  data={handleDownloadCSV()}
                  className="download-btn my-auto"
                  filename={`CandidateChecker-${name}-report.csv`}
                >
                  {downloadIcon}
                </CSVLink>
              )}
            </h5>

            {((socials && !showNotFounds) || (socialsFullList && showNotFounds)) &&
            (showNotFounds ? socialsFullList : socials).length > 0 ? (
              (showNotFounds ? socialsFullList : socials).map(
                (item, index) =>
                  (item.category === selectedCategory || selectedCategory === 'All') && (
                    <div className={`value-row ${!(user && user.id) && index > 9 ? 'hidden' : ''} ${i18n.language === 'ar' ? 'right-align' : ''}`} key={item.value}>
                      <span className={item.status === '200' ? 'found' : 'not-found'}></span>
                      <img src={item.name === 'Gravatar' && item.value ? item.value : item.icon} alt="email" />
                      <div className="w-full">
                        <div className="value-name">{item.name}</div>
                        {item.value ? (
                          <div className="d-flex">
                            <div className="value-text social text-truncate">
                              <a href={item.value} target="_blank" title={item.value} rel='noopener'>
                                {item.value}
                              </a>
                            </div>
                            {!(user && user.id) && index > 9 ? (
                              <></>
                            ) : (
                              <>
                                <input
                                  ref={inputElement}
                                  value={item.value}
                                  style={{visibility: 'hidden', height: 0, width: 0}}
                                  className={i18n.language === 'ar' ? "typing-rl" : ''}
                                />
                                <div
                                  className={`copy ${i18n.language === 'ar' ? 'right-align' : ''}`}
                                  onClick={e => {
                                    handleInputClick(e, item.value);
                                  }}
                                  title={copied && copied === item.value ? t('copied') : t('click-to-copy')}
                                >
                                  {copyIcon}
                                </div>
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div className="value-text social">
                              <span>{t('not-found')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ),
              )
            ) : (
              <div className="text-center">{(t('nothing-to-show'))}</div>
            )}
            {!(user && user.id) && socials && socials.length > 9 && (
              <button
                className="button sign-in mx-auto modal-btn blur-btn d-none d-sm-flex"
                onClick={handleLoginOrLogout}
              >
                <img src={googleIcon} className="google-icon" alt="Google" />
                {t('sign-in-with-Google')}
              </button>
            )}
            {!(user && user.id) && socials && socials.length > 9 && (
              <button className="button sign-in mx-auto modal-btn blur-btn d-sm-none" onClick={handleLoginOrLogout}>
                <img src={googleIcon} className="google-icon" alt="Google" />
                {t('sign-in')}
              </button>
            )}
          </div>
        </div>
      </div>
      {openModal && <Upgrade openModal={openModal} setOpenModal={setOpenModal} simpleSignIn />}
    </>
  );
};
